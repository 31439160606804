<template>
	<div class="p-grid">
		<div class="p-col-12">
			<h1>Vos Campagnes</h1>
			<p class="text-muted">Une campagne vous permet de suivre l'évolution de la promotion votre profil ou services sur internet,
				sur votre site web, sur les cartes, ou encore sur le flocage de vos véhicules.</p>
		</div>

		<div class="p-col-12">
			<div class="card">
				<div class="p-fluid">
					<CampaignsList></CampaignsList>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CampaignsList from '../components/app/crm/CampaignsList.vue';

export default {
	components:{
		CampaignsList,
	}
}
</script>

<style scoped>

</style>
