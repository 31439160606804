<template>
	<div class="p-grid">
		<div class="p-col-12">
			<DataTable :value="campaigns" :lazy="true" class="p-datatable-gridlines" dataKey="id" :paginator="true" :rows="rows"
					   :totalRecords="totalEntities" :rowHover="false" :loading="loading" @page="onPage($event)" @sort="onSort($event)" responsiveLayout="scroll">
				<template #header>
					<div class="p-d-flex p-jc-between p-ai-center">
						<div class="left">
							<Button label="Créer une campagne" icon="pi pi-plus" @click="resetWizard" class="b-button p-button"></Button>
						</div>

						<div class="right">

						</div>
					</div>
				</template>
				<template #empty>
					<div class="p-fluid p-text-center">
						<small class="text-muted">Pas de campagne 😔<br><a @click.prevent="resetWizard" href="#">Créez en une dès maintenant !</a></small>
					</div>
				</template>
				<template #loading>
					<small class="text-muted">Chargement des campagnes</small>
				</template>
				<Column field="name" header="Nom"></Column>
				<Column field="dateStart" header="Date">
					<template #body="{data}">
						<span v-if="data.dateStart !== null" class="image-text">{{ tmsRangeToHumanRange(data.dateStart, data.dateEnd) }}</span>
						<span v-else class="text-muted">Pas de date</span>
					</template>
				</Column>
				<Column field="id" header="">
					<template #body="{data}">
						<div v-if="data.visible" class="p-fluid p-text-center">
							<Button icon="pi pi-eye" label="Désactiver" @click="changeVisibility(data, false)" class="p-button-outlined p-button-danger"></Button>
						</div>

						<div v-else class="p-fluid p-text-center">
							<Button icon="pi pi-eye-slash" label="Activer" @click="changeVisibility(data, true)" class="p-button-outlined"></Button>
						</div>
					</template>
				</Column>
			</DataTable>

			<Dialog :style="{'max-width': '550px',width: '90%'}" v-model:visible="wizardDialog" :modal="true"  >
				<template #header>
					<h5>Assitant de création de campagne - <small class="text-muted">étape {{ wizardStep+1 }}</small></h5>
				</template>

				<div class="modal-content p-fluid">
					<div v-if="wizardStep === 0" class="p-fluid">
						<p>Une campagne vous permet de suivre l'évolution de la promotion votre profil ou services sur internet,
							sur votre site web, sur les cartes, ou encore sur le flocage de vos véhicules.</p>
						<Message severity="warn" :closable="false">
							ATTENTION : <b>une fois créée, une campagne ne peut être supprimée</b>.
						</Message>
						<p>Commencez par donner un nom à votre campagne :</p>
						<div class="p-field">
							<InputText v-model="wizardCampaignName"
									   placeholder="Foire Paris 2021 ou Flocage véhicules"
									   id="name1" type="text" />
						</div>
					</div>
					<div v-else-if="wizardStep === 1" class="p-fluid">
						<p>La première étape consiste à sélectionner le type de campagne que vous souhaitez créer.</p>
						<div class="p-field">
							<div class="p-field-radiobutton">
								<RadioButton name="permanente" :value="0" v-model="wizardCampaignType" />
								<label for="permanente"><b>Campagne permanente</b></label>
							</div>
							<div class="p-fluid p-mb-4">
								<small class="text-muted">
									Une campagne <b>permanente</b> est illimitée dans le temps et est donc utile pour suivre l'impact
									d'un flocage d'un véhicule ou d'une vitrine par exemple.
								</small>
							</div>

							<div class="p-field-radiobutton">
								<RadioButton name="temporaire" :value="1" v-model="wizardCampaignType" />
								<label for="temporaire"><b>Campagne temporaire</b></label>
							</div>
							<div class="p-fluid p-mb-4">
								<small class="text-muted">
									Une campagne <b>temporaire</b> est limitée dans le temps et est donc propice pour les événements
									de type congrès ou foire. Elle sera affichée sur votre profil public pour toute sa durée.
								</small>
							</div>
						</div>

						<div v-if="wizardCampaignType === 1" class="p-fluid">
							<div class="p-field">
								<label for="datestart">Date de début <small class="p-error">obligatoire</small></label>
								<Calendar :showTime="true" hourFormat="24" :showButtonBar="false"
										  name="datestart" v-model="wizardDateStart" />
							</div>

							<div class="p-field">
								<label for="dateend">Date de fin <small class="p-error">obligatoire</small></label>
								<Calendar :showTime="true" hourFormat="24" :showButtonBar="false"
										  name="dateend" v-model="wizardDateEnd" />
							</div>
						</div>

					</div>
					<div v-else-if="wizardStep === 2" class="p-fluid">
						<div v-if="wizardCampaignType === 1" class="p-fluid">
							<p>Une campagne peut disposer d'une adresse à afficher sur votre profil public. Vous pouvez aussi
								laisser ces champs vides.</p>
							<div class="p-field">
								<label for="name1">Emplacement</label>
								<InputText v-model="wizardAddr.onLocation"
										   placeholder="Allée C, Stand 23"
										   id="street" type="text" />
							</div>
							<div class="p-field">
								<label for="name1">Adresse</label>
								<InputText v-model="wizardAddr.street" id="street" type="text" />
							</div>
							<div class="p-field">
								<label for="name1">Code Postal</label>
								<InputText v-model="wizardAddr.postalCode" id="postalcode" type="text" />
							</div>
							<div class="p-field">
								<label for="name1">Ville</label>
								<InputText v-model="wizardAddr.city" id="city" type="text" />
							</div>
						</div>
						<div v-else class="p-fluid">
							<p>Une campagne permanente n'a pas d'adresse.</p>
						</div>
					</div>
					<div v-else-if="wizardStep === 3" class="p-fluid">
						<div v-if="wizardCampaignType === 0" class="p-fluid">
							<p>Votre campagne {{ wizardCampaignName }} est prête à être créée !</p>
						</div>
						<div v-else class="p-fluid">

							<div class="p-text-center p-mb-3">
								<p><b>{{ wizardCampaignName }}</b></p>
								<p><small><b>{{ isoRangeToHumanRange(wizardDateStart,wizardDateEnd) }}</b></small></p>
								<p><br>
									<span>{{ wizardAddr.onLocation }}</span><br>
									<span>{{ wizardAddr.street }}</span><br>
									<span>{{ wizardAddr.postalCode }} {{ wizardAddr.city }}</span><br>
									<span>{{ wizardAddr.country }}</span><br>
								</p>
							</div>
							<p>Votre campagne est prête à être créée !</p>
						</div>

					</div>
				</div>

				<template #footer>
					<Button v-if="wizardStep === 0" @click="wizardDialog = false" label="Annuler" icon="pi pi-times" class="p-button-danger"/>
					<Button v-if="wizardStep > 0" @click="--wizardStep" label="Précédent" icon="pi pi-arrow-left" class="p-button-text"/>
					<Button label="Suivant" v-if="wizardStep < 3" :disabled="nextDisabled"  icon="pi pi-arrow-right" @click="wizardStep++" autofocus />
					<Button label="Créer la campagne" v-if="wizardStep === 3" class="p-button-success"  icon="pi pi-save" @click="createCampaign" autofocus />
				</template>
			</Dialog>

		</div>

	</div>
</template>

<script>
	import CampaignService from "../../../service/CampaignService";
	import calendarMixin from "../../../mixins/calendarMixin";

	const Campaign = {
		type: {
			timed: 1,
			timeless: 0,
		}
	};

	export default {
		data() {
			return {
				dut: undefined,
				campaigns: [],
				totalEntities: 0,
				rows: 10,
				loading: false,
				order: undefined,
				orderDirection: false,
				page: 1,
				statusFilter: undefined,
				timeoutPtr: undefined,
				wizardDialog: false,
				wizardStep: 0,
				wizardCampaignType: Campaign.type.timeless,
				wizardDateStart: undefined,
				wizardDateEnd: undefined,
				wizardCampaignName: '',
				wizardAddr: {
					street:'',
					postalCode:'',
					city:'',
					onLocation: '',
				}
			}
		},
		created(){
			this.campaignService = new CampaignService()
		},
		mounted(){
			this.loadData()
			;
		},
		methods: {
			changeVisibility(campaign, state){
				campaign.visible = state;

				this.campaignService.updateCampagin(campaign['@id'], campaign)
						.then(data => {
							if(data.data.error === true){
								throw new Error(data.data.message);
							}

						})
						.catch(err => {
							this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						})
						.finally(() => {
							this.loadData();
						})
			},
			createCampaign() {
				const campaign = {
					name: this.wizardCampaignName,
					onSiteLocation: this.wizardAddr.onLocation,
					addrStreet: this.wizardAddr.street,
					addrCity: this.wizardAddr.city,
					addrPostalCode: this.wizardAddr.postalCode,
				};

				if(this.wizardDateStart !== undefined){
					campaign.dateStart = this.wizardDateStart;
				}

				if(this.wizardDateEnd !== undefined){
					campaign.dateEnd = this.wizardDateEnd;
				}

				this.campaignService.createCampaign(campaign)
						.then(data => {
							if(data.data.error === true){
								throw new Error(data.data.message);
							}

						})
						.catch(err => {
							this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						})
						.finally(() => {
							this.wizardDialog = false;
							this.loadData();
						})
			},
			resetWizard(){
				this.wizardDialog = true;
				this.wizardStep = 0;
				this.wizardCampaignType = Campaign.type.timeless;
				this.wizardDateStart = undefined;
				this.wizardDateEnd = undefined;
				this.wizardCampaignName = '';
				this.wizardAddr = {
					street:'',
							postalCode:'',
							city:'',
							onLocation: '',
				};
			},
			registerReload(){
				const vm = this;
				if(this.timeoutPtr !== undefined){
					this.cancelReload();
				}
				this.timeoutPtr = setTimeout(() => {
					vm.loadData();
				}, 400)
			},
			cancelReload(){
				try {
					clearTimeout(this.timeoutPtr);
				} catch (e) {
					console.warn(e);
				}
			},
			viewContact(contact) {
				this.$router.push({ name: 'crm-contacts-profile', params: { guid: contact.guid } })
			},
			onPage(event) {
				this.page = event.page + 1;
				this.loadData();
			},
			onSort(event) {
				console.log(event);
			},
			loadData(){
				const vm = this;
				this.loading = true;
				this.campaignService.getContactEvent(
						undefined,
						undefined,
						this.rows,
						this.page,
						this.order,
						this.orderDirection,
				)
						.then(data => {
							if(data.data.error === true){
								throw new Error(data.data.message);
							}

							const p = data.data;
							vm.campaigns = p.data;
							vm.totalEntities = p['@meta'].total;

						})
						.catch(err => {
							vm.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						})
						.finally(() => {
							vm.loading = false;
						})
			}
		},
		computed:{
			nextDisabled(){
				/*
				wizardStep: 0,
						wizardCampaignType: Campaign.type.timeless,
						wizardDateStart: undefined,
						wizardDateEnd: undefined,//*/
				let isDisabled = false;
				switch (this.wizardStep) {
					case 0:
						isDisabled = false;
						if(this.wizardCampaignName === ''){
							isDisabled = true;
						}
						break;
					case 1:
						if(this.wizardCampaignType === Campaign.type.timed){
							if(this.wizardDateStart !== undefined && this.wizardDateEnd !== undefined){
								isDisabled = false;
							} else {
								isDisabled = true;
							}
						} else {
							isDisabled = false;
						}
						break;
					default:
						isDisabled = false;
				}
				return isDisabled;
			}
		},
		components: {
		},
		mixins:[calendarMixin]
	}
</script>

<style scoped>

</style>
